// @flow strict
import React from 'react';
import styled from '@emotion/styled';
import { useTheme } from 'emotion-theming';

import Hero from '../atoms/Hero';
import Section from '../molecules/Section';
import ContactForm from '../organisms/ContactForm';
import SolidButton from '../atoms/SolidButton';
import TextBlock from '../atoms/TextBlock';

type Props = {
  pageData: {
    hero: String,
    title: String,
    messages: String[],
    phone: String,
  },
};

const ContactPage = ({ pageData }: Props) => {
  const theme = useTheme();
  const phoneText = `TEL: ${pageData.phone}`;
  const phoneLink = `tel:${pageData.phone}`;

  return (
    <div>
      <Hero color={pageData.hero} />
      <Section title={pageData.title}>
        {pageData.messages.map((message, index) =>
          message != null ? (
            <TextBlock
              key={index.toString()}
              text={message}
              align="center"
              color={theme.colors.primary}
            />
          ) : (
            <Margin key={index.toString()} />
          )
        )}
        <ContactForm />
      </Section>
    </div>
  );
};

const Margin = styled.div({
  margin: '1rem',
});

export default ContactPage;
