// @flow strict
import React from 'react';
import styled from '@emotion/styled';

type Props = {
  background: String,
  color: String,
  text: String,
};

const SolidButton = ({ background, color, text }: Props) => (
  <Root background={background}>
    <Text color={color}>{text}</Text>
  </Root>
);

const Root = styled.div((props) => ({
  backgroundColor: props.background,
  display: 'inline-block',
  padding: '0.5rem 1rem',
}));

const Text = styled.div((props) => ({
  color: props.color,
  fontWeight: 'bold',
}));

export default SolidButton;
