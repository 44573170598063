// @flow strict
import React from 'react';
import styled from '@emotion/styled';
import { useTheme } from 'emotion-theming';

type Props = {
  align: 'left' | 'right' | 'center',
  color: string,
  text: String,
};

const TextBlock = (props: Props) => {
  const theme = useTheme();
  const { align = 'left', color = theme.colors.text, text } = props;

  return (
    <Root align={align} color={color}>
      {text.split('\n').map((str, index) => (
        <React.Fragment key={index.toString()}>
          {str}
          <br />
        </React.Fragment>
      ))}
    </Root>
  );
};

const Root = styled.p((props) => ({
  color: props.color,
  textAlign: props.align,
}));

export default TextBlock;
