import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

import Template from '../templates/ContactPage';

const query = graphql`
  query {
    pageYaml(page: { eq: "contact" }) {
      hero
      title
      messages
      phone
    }
  }
`;

const IndexPage = () => (
  <StaticQuery query={query} render={(data) => <Template pageData={data.pageYaml} />} />
);

export default IndexPage;
