// @flow strict
import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import styled from '@emotion/styled';

type Props = {
  color?: 'pink' | 'brown' | 'yellow',
};

const query = graphql`
  query {
    pink: file(relativePath: { eq: "Hero/pink.png" }) {
      childImageSharp {
        fluid(maxWidth: 1060) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    brown: file(relativePath: { eq: "Hero/brown.png" }) {
      childImageSharp {
        fluid(maxWidth: 1060) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    yellow: file(relativePath: { eq: "Hero/yellow.png" }) {
      childImageSharp {
        fluid(maxWidth: 1060) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    orange: file(relativePath: { eq: "Hero/orange.png" }) {
      childImageSharp {
        fluid(maxWidth: 1060) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    cyan: file(relativePath: { eq: "Hero/cyan.png" }) {
      childImageSharp {
        fluid(maxWidth: 1060) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const Hero = ({ color }: Props) => (
  <StaticQuery
    query={query}
    render={(data) => {
      switch (color) {
        case 'pink':
          return <Image fluid={data.pink.childImageSharp.fluid} alt="Hero" />;
        case 'brown':
          return <Image fluid={data.brown.childImageSharp.fluid} alt="Hero" />;
        case 'yellow':
          return <Image fluid={data.yellow.childImageSharp.fluid} alt="Hero" />;
        case 'orange':
          return <Image fluid={data.orange.childImageSharp.fluid} alt="Hero" />;
        case 'cyan':
          return <Image fluid={data.cyan.childImageSharp.fluid} alt="Hero" />;
        default:
          return null;
      }
    }}
  />
);

Hero.defaultProps = {
  color: 'pink',
};

const Image = styled(Img)({
  maxWidth: 1060,
});

export default Hero;
