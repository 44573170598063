// @flow strict
import React from 'react';
import styled from '@emotion/styled';

import OrnamentalButton from '../atoms/OrnamentalButton';

const ContactForm = () => (
  <div>
    <form
      name="contact"
      method="POST"
      action="thanks"
      data-netlify="true"
      data-netlify-honeybot="bot-field"
    >
      <input type="hidden" name="form-name" value="contact" />
      <input type="hidden" name="bot-field" />
      <table>
        <tbody>
          <tr>
            <HeadCell>
              お名前
              <Asterisk> ＊</Asterisk>
            </HeadCell>
            <Cell>
              <Input
                id="name"
                name="name"
                placeholder="お名前 ＊"
                maxLength="30"
                minLength="2"
                required
                autoComplete="name"
              />
            </Cell>
          </tr>
          <tr>
            <HeadCell>
              お名前 (フリガナ)
              <Asterisk> ＊</Asterisk>
            </HeadCell>
            <Cell>
              <Input
                id="name"
                name="kana"
                placeholder="お名前 (フリガナ) ＊"
                maxLength="60"
                minLength="2"
                required
                autoComplete="kana"
              />
            </Cell>
          </tr>
          <tr>
            <HeadCell>ご住所</HeadCell>
            <Cell>
              <div>郵便番号</div>
              <InputZip
                id="zip1"
                name="zip1"
                placeholder=""
                maxLength="3"
                autoComplete="zip1"
              />
              <span> - </span>
              <InputZip
                id="zip2"
                name="zip2"
                placeholder=""
                maxLength="4"
                autoComplete="zip2"
              />
              <div>都道府県</div>
              <Control as="select" id="pref" name="pref">
                <option value="">都道府県</option>
                <option value="北海道">北海道</option>
                <option value="青森県">青森県</option>
                <option value="岩手県">岩手県</option>
                <option value="宮城県">宮城県</option>
                <option value="秋田県">秋田県</option>
                <option value="山形県">山形県</option>
                <option value="福島県">福島県</option>
                <option value="茨城県">茨城県</option>
                <option value="栃木県">栃木県</option>
                <option value="群馬県">群馬県</option>
                <option value="埼玉県">埼玉県</option>
                <option value="千葉県">千葉県</option>
                <option value="東京都">東京都</option>
                <option value="神奈川県">神奈川県</option>
                <option value="新潟県">新潟県</option>
                <option value="富山県">富山県</option>
                <option value="石川県">石川県</option>
                <option value="福井県">福井県</option>
                <option value="山梨県">山梨県</option>
                <option value="長野県">長野県</option>
                <option value="岐阜県">岐阜県</option>
                <option value="静岡県">静岡県</option>
                <option value="愛知県">愛知県</option>
                <option value="三重県">三重県</option>
                <option value="滋賀県">滋賀県</option>
                <option value="京都府">京都府</option>
                <option value="大阪府">大阪府</option>
                <option value="兵庫県">兵庫県</option>
                <option value="奈良県">奈良県</option>
                <option value="和歌山県">和歌山県</option>
                <option value="鳥取県">鳥取県</option>
                <option value="島根県">島根県</option>
                <option value="岡山県">岡山県</option>
                <option value="広島県">広島県</option>
                <option value="山口県">山口県</option>
                <option value="徳島県">徳島県</option>
                <option value="香川県">香川県</option>
                <option value="愛媛県">愛媛県</option>
                <option value="高知県">高知県</option>
                <option value="福岡県">福岡県</option>
                <option value="佐賀県">佐賀県</option>
                <option value="長崎県">長崎県</option>
                <option value="熊本県">熊本県</option>
                <option value="大分県">大分県</option>
                <option value="宮崎県">宮崎県</option>
                <option value="鹿児島県">鹿児島県</option>
                <option value="沖縄県">沖縄県</option>
              </Control>
              <div>市町村名</div>
              <Input
                id="address1"
                name="address1"
                placeholder="市町村名"
                maxLength="50"
                minLength="2"
                autoComplete="address1"
              />
              <div>番地・ビル名・部屋番号</div>
              <Input
                id="address2"
                name="address2"
                placeholder="番地・ビル名・部屋番号"
                maxLength="80"
                minLength="2"
                autoComplete="address2"
              />
            </Cell>
          </tr>
          <tr>
            <HeadCell>
              メールアドレス
              <Asterisk> ＊</Asterisk>
            </HeadCell>
            <Cell>
              <Input
                id="email"
                name="email"
                type="email"
                placeholder="メールアドレス ＊"
                pattern="^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
                required
                autoComplete="email"
              />
            </Cell>
          </tr>
          <tr>
            <HeadCell>
              電話番号
              <Asterisk> ＊</Asterisk>
            </HeadCell>
            <Cell>
              <Input
                id="phone"
                name="phone"
                placeholder="電話番号 ＊"
                maxLength="13"
                minLength="2"
                required
                autoComplete="phone"
              />
            </Cell>
          </tr>
          <tr>
            <HeadCell>
              お問い合わせ内容
              <Asterisk> ＊</Asterisk>
            </HeadCell>
            <Cell>
              <Input
                as="textarea"
                id="content"
                name="centent"
                placeholder="お問い合わせ内容をご入力ください ＊"
                rows="8"
                required
              />
            </Cell>
          </tr>
        </tbody>
      </table>
      <ButtonLayout>
        <ButtonControl type="submit">
          <OrnamentalButton text="お問い合わせ内容を送信する" />
        </ButtonControl>
      </ButtonLayout>
    </form>
  </div>
);

const Cell = styled.td((props) => ({
  border: `1px solid ${props.theme.colors.primary}`,
  padding: '1rem',
  '&:first-of-type': {
    paddingLeft: '1rem',
  },
  '&:last-of-type': {
    paddingRight: '1rem',
  },
}));

const HeadCell = styled(Cell)((props) => ({
  display: 'none',
  [props.theme.mediaQuery.minTablet]: {
    backgroundColor: '#FEF1E4',
    display: 'table-cell',
    fontWeight: 'normal',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    width: 50,
  },
}));

const Asterisk = styled.span((props) => ({
  color: props.theme.colors.primary,
}));

const Control = styled.input({
  border: '1px solid #eee',
  borderRadius: 3,
  padding: '0.5rem 1rem',
  width: 'auto',
  '&:focus': {
    border: '1px solid #ccc',
    outline: 0,
  },
  '&::placeholder': {
    color: '#888',
  },
});

const Input = styled(Control)({
  width: '100%',
});

const InputZip = styled(Control)({
  width: 80,
});

const ButtonLayout = styled.div({
  textAlign: 'center',
});

const ButtonControl = styled.button({
  border: 'none',
  background: 'transparent',
});

export default ContactForm;
